<div class="callback-container text-center">
    <div class="container h-100">
        <div class="row h-100 justify-content-center align-items-center">
            <div
                *ngIf="isSuccess"
                class="alert alert-success d-flex p-5"
                role="alert"
            >
                <div class="pe-2">
                    <fa-icon [icon]="faCheckCircle" size="2x"></fa-icon>
                </div>
                <div></div>
                <h4>{{ "Wallet.DepositSuccess" | translate }}</h4>
            </div>

            <div
                *ngIf="!isSuccess"
                class="alert alert-danger d-flex p-5"
                role="alert"
            >
                <div class="pe-2">
                    <fa-icon [icon]="faTimesCircle" size="2x"></fa-icon>
                </div>
                <div></div>
                <h4>{{ "Wallet.OperationNotComplete" | translate }}</h4>
                <hr />
                <h4>{{ description }}</h4>
            </div>
        </div>
    </div>
</div>
