import { Component, OnInit } from "@angular/core";
import {
  faCheck,
  faCheckCircle,
  faTimes,
  faTimesCircle,
} from "@fortawesome/pro-light-svg-icons";
import { ActivatedRoute } from "@angular/router";
import { AppRouter } from "@core/helper/app-router";

@Component({
  selector: "app-deposit-fiat-callback",
  templateUrl: "./deposit-fiat-callback.component.html",
  styleUrls: ["./deposit-fiat-callback.component.scss"],
})
export class DepositFiatCallbackComponent implements OnInit {
  faCheckCircle = faCheckCircle;
  faTimesCircle = faTimesCircle;
  isSuccess: boolean;
  description: boolean;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: AppRouter
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.isSuccess = params["success"]?.toLowerCase() === "true";
      this.description = params["desc"];
      const self = this;
      setTimeout(() => {
        self.router.navigate("/wallet/main");
      }, 5000);
    });
  }
}
